.logo {
    font-size: 2rem;
    font-weight: 700;
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    cursor: pointer;
  }

.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: var(--header-height);
    background-color: var(--card-background);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.menu-toggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-color);
    cursor: pointer;
    padding: 5px;
}

.mobile-nav {
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--card-background);
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 1000;
}

.mobile-nav .nav-link {
    font-size: 18px;
    padding: 15px 0;
    text-decoration: none;
    color: var(--text-color);
    border-bottom: 1px solid #eee;
}

.mobile-nav .logout-button {
    margin-top: 20px;
    background: none;
    border: none;
    font-size: 18px;
    color: var(--text-color);
    cursor: pointer;
    padding: 15px 0;
    text-align: left;
}

@media (min-width: 769px) {
    .mobile-header {
        display: none;
    }
}