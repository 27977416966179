@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.action-subheader {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  text-align: center;
}

.no-words-container {
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
}

.no-words-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 100%);
}

.engagement-header {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
}

.stats-container {
  justify-content: space-around;
  flex-wrap: wrap;
}

.stats-container a {
  text-decoration: none;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 150px;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.stat-icon {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.stat-icon.trophy {
  color: #FFD700;
}

.stat-icon.calendar {
  color: #4CAF50;
}

.stat-label {
  font-size: 1.1rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
  font-weight: 300;
}

.stat-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}

.encouragement {
  font-size: 1.3rem;
  color: #6c757d;
  margin-bottom: 2rem;
  font-style: italic;
  font-weight: 300;
  font-style: italic;
}

.add-word-button {
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 100%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 201, 255, 0.3);
}

.add-word-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 201, 255, 0.4);
}

.get-started-guide {
  margin-top: 3rem;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  text-align: left;
}

.get-started-guide h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.get-started-guide ol {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.get-started-guide li {
  margin-bottom: 0.5rem;
  color: #555;
}

.guide-button {
  background-color: transparent;
  color: #00C9FF;
  border: 2px solid #00C9FF;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
}

.guide-button:hover {
  background-color: #00C9FF;
  color: white;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .action-subheader {
    font-size: 1rem;
  }

  .engagement-header {
    font-size: 1.5rem;
  }

  .no-words-container {
    padding: 1.5rem;
  }

  .stat-value {
    font-size: 2rem;
  }

  .encouragement {
    font-size: 1.1rem;
  }
}