.quick-add-form {
  max-width: 500px;
  width: 90%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.quick-add-form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--text-color);
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: var(--text-color);
}

.quick-add-form input[type="text"],
.quick-add-form select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f8f8f8;
  color: var(--text-color);
  transition: var(--transition);
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
}

.quick-add-form select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 30px;
}

.quick-add-form input[type="text"]:focus,
.quick-add-form select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.cancel-button,
.save-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button {
  background-color: transparent;
  color: var(--text-color);
  width: auto;
  min-width: 0;
  flex: 0 0 auto;
}

.cancel-button:hover {
  opacity: 0.8;
}

.add-button {
  background-color: var(--accent-color);
  color: white;
}

.add-button:hover {
  background-color: #3a7bc8;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
}

.suggestion-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.suggestion-header {
  margin-bottom: 5px;
}

.part-of-speech-list {
  list-style-type: none;
  padding-left: 10px;
}

.part-of-speech-item {
  margin-top: 5px;
}

.definition-list {
  margin-top: 5px;
  padding-left: 20px;
}

.definition-item {
  cursor: pointer;
  padding: 2px 5px;
  margin: 2px 0;
  transition: background-color 0.2s ease;
  border-radius: 3px;
}

.definition-item:hover {
  background-color: #e6f3ff;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message button {
  background-color: #c62828;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: #b71c1c;
}

.examples-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.examples-list li {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.examples-list li:hover {
  background-color: #f0f0f0;
}

.examples-list li.selected {
  background-color: #e6f7ff;
  border-color: #91d5ff;
}

.loading {
  text-align: center;
  padding: 10px;
  font-style: italic;
  color: #666;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.save-button {
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: white;
}

.cancel-button:hover,
.save-button:hover {
  opacity: 0.8;
}

.quick-add-form .form-group {
  position: relative;
  margin-bottom: 15px;
}

.quick-add-form .input-wrapper {
  position: relative;
}
