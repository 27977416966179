.audio-player-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  color: inherit;
  font-size: 1.1rem;
  padding: 0px 10px;
}

.audio-player-button:hover {
  opacity: 0.8;
}

.audio-player-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
