.desktop-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: var(--header-height);
    background-color: var(--card-background);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 2rem;
    font-weight: 700;
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    cursor: pointer;
  }
  
  .desktop-nav {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
    justify-content: center;
  }
  
  .nav-link {
    font-size: 16px;
    background-color: transparent;
    color: black;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .nav-link:hover,
  .logout-button:hover {
    background-color: rgba(0, 201, 255, 0.1);
  }
  
  .nav-link.active {
    font-weight: bold;
  }
  
  .logout-button {
    border: none;
    cursor: pointer;
    font-family: inherit;
    background: none;
    color: black;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  @media (min-width: 769px) {
    .desktop-header {
      display: flex;
    }
  }