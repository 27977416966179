.progress-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f5f7fa;
  box-sizing: border-box;
}

.progress-title {
  text-align: center;
  color: var(--dark-blue);
  margin-bottom: 30px;
  font-size: 2rem;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.chart-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chart-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--dark-blue);
  font-size: 1.2rem;
  text-align: center;
}

.stats-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.stats-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--dark-blue);
  font-size: 1.2rem;
  text-align: center;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.stat-item {
  text-align: center;
  background-color: #f0f4f8;
  padding: 15px;
  border-radius: 8px;
}

.stat-label {
  font-weight: bold;
  color: var(--dark-blue);
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin: 0;
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: var(--dark-blue);
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .chart-grid {
    grid-template-columns: 1fr;
  }

  .stats-grid {
    grid-template-columns: 1fr;
  }
}