:root {
  --primary-color: #0077be;
  --secondary-color: #00894f;
  --accent-color: #00c3ff;
  --text-color: #333;
  --background-color: #f4f7f9;
  --correct-color: #2ecc71;
  --incorrect-color: #e74c3c;
  --card-padding: 16px;
}

.sentences-section {
  max-width: 800px;
  margin: 0 auto;
}

.sentence-card {
  background-color: white;
  border-radius: 16px;
  padding: var(--card-padding);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.sentence-word {
  font-size: 3rem;
  text-align: center;
  color: var(--text-color);
  margin-top: 30px;
  margin-bottom: 12px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: -1px;
}

.sentences-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sentence-option {
  background-color: var(--background-color);
  border: none;
  border-radius: 12px;
  padding: 1.5rem;
  font-size: 1.15rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.sentence-option::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  transform: scaleY(0);
  transition: transform 0.3s ease;
}

.sentence-option.correct::before {
  transform: scaleY(1);
  background-color: var(--correct-color);
}

.sentence-option.incorrect::before {
  transform: scaleY(1);
  background-color: var(--incorrect-color);
}

.sentence-option:hover::before {
  transform: scaleY(1);
}

.sentence-option:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.sentence-option.correct {
  background-color: rgba(46, 204, 113, 0.1);
  color: var(--correct-color);
}

.sentence-option.incorrect {
  background-color: rgba(231, 76, 60, 0.1);
  color: var(--incorrect-color);
}

.sentence-text {
  flex: 1;
}

.sentence-icon {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  :root {
    --card-padding: 1.5rem;
  }

  .sentence-word {
    font-size: 2.5rem;
  }

  .sentence-option {
    font-size: 1.05rem;
    padding: 1.2rem;
  }
}

.sentence-part-of-speech {
  text-align: center;
  color: #999;
  font-size: 1.2rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.sentence-details {
  text-align: center;
  color: #999;
  font-size: 1rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.sentence-word,
.part-of-speech,
.pronunciation {
  text-transform: lowercase;
}

.separator {
  margin: 0 0.5rem;
}

.next-word-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
  display: block;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.next-word-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.next-word-button:active {
  transform: translateY(0);
}

.sentence-card__content--center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust as needed */
  text-align: center;
  font-size: 1.2rem;
  color: #666;
}

.answer {
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1.5rem;
  box-shadow: inset 0 2px 4px var(--shadow-color);
}

.answer p {
  margin: 0.5rem 0;
  color: var(--text-color);
}

.skeleton-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-word,
.skeleton-details,
.skeleton-sentence {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

.skeleton-word {
  height: 40px;
  width: 180px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.skeleton-details {
  height: 20px;
  width: 120px;
  margin-bottom: 30px;
}

.skeleton-sentences {
  width: 100%;
}

.skeleton-sentence {
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}