.review-card {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.review-word {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 12px;
  color: var(--dark-blue);
  font-weight: bold;
  margin-top: 30px;
}

.review-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem 0;
}

.btn-hard {
  background-color: #e74c3c;
}

.btn-medium {
  background-color: #3498db;
}

.btn-easy {
  background-color: #2ecc71;
}

.review-buttons button:hover {
  filter: brightness(1.1);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.review-buttons button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-answer-btn {
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: var(--card-background);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.toggle-answer-btn:hover {
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.toggle-answer-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 201, 255, 0.3);
}

.review-instructions {
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1.5rem;
}

.review-instruction {
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.review-explanation {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.review-explanation li {
  margin-bottom: 5px;
  color: var(--text-color);
}

.review-explanation strong {
  color: var(--primary-color);
}

.review-buttons button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 600;
  min-width: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.review-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.answer {
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: inset 0 2px 4px var(--shadow-color);
}

.answer p {
  margin: 0.5rem 0;
  color: var(--text-color);
}

.review-details {
  text-align: center;
  color: #999;
  font-size: 1rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.review-details .part-of-speech,
.review-details .pronunciation {
  text-transform: lowercase;
}

.review-details .separator {
  margin: 0 0.5rem;
}

@media (max-width: 768px) {
  .review-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .review-buttons button {
    width: 100%;
  }

  .review-card {
    padding: 1.5rem;
  }

  .review-word {
    font-size: 2.5rem;
  }
}
