.filter-sort {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background-color: var(--card-background);
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--shadow-color);
}

.filter-section,
.sort-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filter-sort-label {
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 5px;
}

.sort-section {
    grid-column: 1 / -1;
}

.icon {
    margin-right: 10px;
    color: #666;
    /* Change this to a neutral color */
    font-size: 18px;
    /* Set a specific size for all icons */
}

.filter-select,
.sort-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter-select:hover,
.sort-select:hover {
    border-color: #999;
}

.filter-select:focus,
.sort-select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 201, 255, 0.2);
}

.filter-select.active-filter {
    font-weight: bold;
}

.filter-select.active-filter option:checked {
    font-weight: bold;
}

.filter-select.active-filter option:not(:checked) {
    font-weight: normal;
}

@media (max-width: 767px) {
    .filter-sort {
      flex-direction: column;
      align-items: stretch;
    }
  
    .filter-section,
    .sort-section {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .filter-section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .filter-select,
    .sort-select {
      width: 100%;
    }
  }