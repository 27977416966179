.word-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .word-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .word-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .word-card__title {
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .word-card__cefr-level {
    font-size: 0.8rem;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 12px;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
  
  .word-card__translation {
    font-style: italic;
    color: #666;
    margin-bottom: 8px;
  }
  
  .word-card__pos-pronunciation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .word-card__definition {
    margin-bottom: 8px;
  }
  
  .word-card__example {
    font-style: italic;
    color: #666;
    margin-bottom: 16px;
  }
  
  .word-card__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .word-card__meta {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #666;
  }
  
  .word-card__next-review,
  .word-card__difficulty {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  
  .word-card__next-review svg,
  .word-card__difficulty svg {
    margin-right: 4px;
  }
  
  .word-card__actions {
    display: flex;
  }
  
  .word-card__button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    margin-left: 8px;
    transition: color 0.3s ease;
  }
  
  .word-card__button--edit {
    color: #3498db;
  }
  
  .word-card__button--delete {
    color: #e74c3c;
  }
  
  .word-card__button--save {
    color: #2ecc71;
  }
  
  .word-card__button--cancel {
    color: #e74c3c;
  }
  
  .word-card__confirm {
    margin-top: 16px;
    text-align: center;
  }
  
  .word-card__confirm p {
    margin-bottom: 8px;
  }
  
  .word-card__confirm button {
    margin: 0 8px;
  }
  
  .edit-form-field {
    margin-bottom: 16px;
  }
  
  .edit-form-field label {
    display: block;
    margin-bottom: 4px;
  }
  
  .edit-form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }