@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #0077be;
  --secondary-color: #00894f;
  --accent-color: #00c3ff;
  --text-color: #333;
  --background-color: #f4f7f9;
  --card-background: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --header-height: 79.5px;
  --header-padding: 20px;
  --menu-item-padding: 20px;
  --z-index-header: 1000;
  --z-index-mobile-menu: 1001;
  --font-family: 'Poppins', sans-serif;
}

/* Base styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Typography */
h1,
h2,
h3 {
  color: var(--dark-blue);
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5rem;
  text-align: center;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

/* Form styles */
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

input,
select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  background-color: white;
  color: var(--text-color);
}

input:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-color);
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

/* App header styles */
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  z-index: var(--z-index-header);
  background-color: var(--card-background);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.app-header h1 {
  margin: 0;
  font-size: 2rem;
  color: var(--bright-blue);
}

.logout-button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: rgba(0, 201, 255, 0.1);
}

/* Word list header styles */
.word-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-word-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  position: relative;
}

.add-word-button:hover {
  background-color: #3a7bc8;
}

.add-word-button:hover::after {
  content: "Hotkey: Ctrl+W";
  position: absolute;
  background-color: var(--dark-blue);
  color: white;
  padding: 5px;
  border-radius: var(--border-radius);
  font-size: 0.8rem;
  white-space: nowrap;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.confirmation-message {
  position: sticky;
  top: var(--header-height); 
  left: 0;
  right: 0;
  background-color: var(--secondary-color);
  color: white;
  padding: 10px;
  border-radius: var(--border-radius);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: fadeIn 0.3s ease-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ... (rest of the styles remain the same) */

.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
  /* Adjust for header and footer */
}

.auth-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.auth-column {
  flex: 1;
  padding: 40px;
}

.auth-form {
  max-width: 400px;
  margin: 0 auto;
}

.auth-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.auth-form input {
  width: 100%;
  margin-bottom: 15px;
}

.auth-form button {
  width: 100%;
}

.switch-auth-mode {
  background-color: transparent;
  color: var(--accent-color);
  border: none;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
  transition: var(--transition);
}

.switch-auth-mode:hover {
  text-decoration: underline;
}

/* Service description styles */
.service-description {
  color: var(--text-color);
}

.service-description h2 {
  margin-bottom: 20px;
  color: var(--dark-blue);
}

.service-description p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.service-description ul {
  list-style-type: none;
  padding-left: 0;
}

.service-description li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.service-description li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--accent-color);
}

/* Footer styles */
.app-footer {
  background-color: var(--card-background);
  text-align: center;
  box-shadow: 0 -2px 10px var(--shadow-color);
  margin-top: auto; /* Important for making footer being at the bottom */
}

.app-main {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
}

.section-title {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.floating-add-button {
  position: fixed;
  bottom: 4.5rem;
  right: 1.5rem;
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.05s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 201, 255, 0.3);
  z-index: 100;
}

.floating-add-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 201, 255, 0.4);
  opacity: 0.8;
}

.floating-add-button span {
  margin-left: 0.5rem;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message button {
  background-color: #c62828;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: #b71c1c;
}

.word-pronunciation {
  font-family: 'Arial Unicode MS', 'Lucida Sans Unicode', sans-serif;
  font-style: italic;
  display: block;
  font-size: 1rem;
  color: #999;
  margin-top: 12px;
}

.add-word-button.centered {
  display: block;
  margin: 20px auto;
}

.loading {
  text-align: center;
  padding: 10px;
  font-style: italic;
  color: #666;
}

.logo {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.desktop-nav button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.desktop-nav button:hover {
  background-color: rgba(0, 201, 255, 0.1);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
  padding: 5px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 var(--header-padding);
}

.get-started-guide {
  margin-top: 3rem;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
}

.get-started-guide ol {
  padding-left: 0;
  list-style-position: inside;
}

.word-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.word-card {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px var(--shadow-color);
  position: relative;
  margin-bottom: 20px;
}

.word-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.word-card__title {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  margin-block-start: 0;
}

.word-card__pos-pronunciation {
  display: flex;
  gap: 10px;
}

.word-card__pos,
.word-card__pronunciation {
  margin: 0;
  font-size: 0.9rem;
  font-style: italic;
  color: #8a8a8a;
}

.word-card__definition {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.word-card__example {
  font-size: 0.9rem;
  font-style: italic;
  color: var(--text-color);
}

.word-card__actions {
  display: flex;
  justify-content: flex-end;
}

.word-card__button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  color: #777;
}

.word-card__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.word-card__confirm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
}

.word-card__input,
.word-card__select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  font-size: 1rem;
}

.word-card__edit {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .word-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
}

.edit-form {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px var(--shadow-color);
  margin-top: 1rem;
}

.edit-form__field {
  margin-bottom: 1rem;
}

.edit-form__label {
  display: block;
  font-size: 0.9rem;
  color: var(--text-color);
  margin-bottom: 0.25rem;
}

.edit-form__input,
.edit-form__select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.edit-form__select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  padding-right: 2rem;
}

.edit-form__actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}

.edit-form__button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-form__button--save {
  background-color: var(--primary-color);
  color: white;
}

.edit-form__button--cancel {
  background-color: var(--secondary-color);
  color: white;
}

.edit-form__button:hover {
  opacity: 0.9;
}

@media (min-width: 768px) {
  .edit-form {
    max-width: 500px;
    margin: 1rem auto;
  }
}

.word-card__meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.8rem;
  color: #777;
}

.word-card__next-review,
.word-card__difficulty {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.word-card__next-review svg,
.word-card__difficulty svg {
  font-size: 1rem;
}

.word-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.edit-form-field {
  margin-bottom: 15px;
}

.edit-form-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--text-color);
}

.edit-form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  /* Grey border */
  border-radius: 4px;
  background-color: white;
  color: var(--text-color);
  box-sizing: border-box;
}

.edit-form-input:focus {
  outline: none;
  border-color: #999;
  /* Darker grey on focus */
  box-shadow: 0 0 0 2px rgba(153, 153, 153, 0.2);
  /* Subtle shadow on focus */
}

.review-details {
  text-align: center;
  color: #999;
  font-size: 1rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.review-details .part-of-speech,
.review-details .pronunciation {
  text-transform: lowercase;
}

.review-details .separator {
  margin: 0 0.5rem;
}

.translation {
  font-weight: normal;
}

.section-title {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

body.quick-add-open {
  overflow: hidden;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .app-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .app-main {
    padding: 1rem;
  }

  .auth-container {
    flex-direction: column;
  }

  .auth-column {
    padding: 20px;
  }

  .auth-form {
    max-width: 100%;
  }

  .cancel-button,
  .add-button {
    padding: 15px 20px;
  }

  .desktop-nav,
  .user-info {
    display: none;
  }

  .desktop-menu {
    display: none;
  }

  .form-header h2 {
    font-size: 1.3rem;
  }

  .logout-button {
    font-size: 0.9rem;
  }

  .menu-toggle {
    display: block;
  }

  .review-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .review-buttons button {
    width: 100%;
  }

  .review-card {
    padding: 1.5rem;
  }

  .review-word {
    font-size: 2.5rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .quick-add-form {
    padding: 15px;
  }
}

@media (min-width: 769px) {
  .word-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  .word-card {
    padding: 1.5rem;
  }
}